import React  from 'react'

class ScheduleForm extends React.Component {

  constructor() {
    super();
    this.state = {
      name: {
        value: '',
        valid: true,
      },
      email: {
        value: '',
        valid: true,
      },
      phone: {
        value: '',
        valid: true,
      },
      zip: {
        value: '',
        valid: true,
      },
      note: {
        value: '',
        valid: true,
      },
    };
  }

  changePhone = (text) => {
    text = text.replace(/\D/g, '');
    if (text.length > 3) text = text.replace(/.{3}/, '$&-');
    if (text.length > 7) text = text.replace(/.{7}/, '$&-');
    this.setState((state) => ({...state, phone:{...state.phone, value:text}}))
  };

  changeZip= (text) => {
    text = text.replace(/\D/g, '');
    this.setState((state) => ({...state, zip:{...state.zip, value:text}}))
  };

  changeInput = (e) => {
    e.persist();
    this.setState((state) => ({...state, [e.target.name]: { ...state[e.target.name], value: e.target.value}}))
  }

  serializeArray = (form) => {
    var arr = {};
    Array.prototype.slice.call(form.elements).forEach(function (field) {
      if (!field.name || field.disabled || ['file', 'reset', 'submit', 'button'].indexOf(field.type) > -1) return;
      if (field.type === 'select-multiple') {
        Array.prototype.slice.call(field.options).forEach(function (option) {
          if (!option.selected) return;
          arr.push({
            name: field.name,
            value: option.value
          });
        });
        return;
      }
      if (['checkbox', 'radio'].indexOf(field.type) >-1 && !field.checked) return;
      arr[field.name] = field.value;
    });
    return arr;
  };

  submitfunc = (e) => {
    e.preventDefault();
    let valid = true

    if (!this.state.name.value.includes(' ')) {
      valid = false
      this.setState((state) => ({...state,name:{...state.name, valid: false, value: ''}}));
      return
    } else {
      this.setState((state) => ({...state,name:{...state.name, valid: true}}));
    }

    if (this.state.email.value.length !== 0 || this.state.phone.value.length !== 0) {
      this.setState((state) => ({...state,email:{...state.email, valid: true}}));
      this.setState((state) => ({...state,phone:{...state.phone, valid: true}}));
    } else {
      valid = false
      this.setState((state) => ({...state,email:{...state.email, valid: false}}));
      this.setState((state) => ({...state,phone:{...state.phone, valid: false}}));
      return
    }

    if (this.state.phone.value.length !==0 && this.state.phone.value.length !==12) {
      valid = false
      this.setState((state) => ({...state,phone:{...state.phone, valid: false, value: ''}}));
      return
    } else {
      this.setState((state) => ({...state,phone:{...state.phone, valid: true}}));
    }

    if (this.state.zip.value.length === 5) {
      this.setState((state) => ({...state,zip:{...state.zip, valid: true}}));
    } else {
      valid = false
      this.setState((state) => ({...state,zip:{...state.zip,  valid: false, value: ''}}));
      return
    }


    var form = this.serializeArray(document.querySelector('#schedule_form'));
    document.getElementById('schedule_form').reset()


    if (valid) {
      document.querySelector('#formmodalclose').click()
      document.getElementById('loaderbtn').click()
      const $ = window.$;
      const formId = 'schedule_form';
      const responseTextId = 'response-text';
      const url = 'https://hooks.zapier.com/hooks/catch/1846901/b9k1d9m/';
      const Http = new XMLHttpRequest();
      const form = window.document.getElementById(formId);
      function handleStateChange() {
        if(Http.readyState === XMLHttpRequest.DONE) {
          var status = Http.status;
          if (status >= 200 && status < 400) {
            document.getElementById('loaderbtnclose').click();
            document.getElementById('thankspagebtn').click();
            document.getElementById('apiresponsemsg').innerHTML = 'Thank you for your request, someone will contact you soon';
            $(form).find('input, input:text, input:password, input:file, select, textarea').val('');
          } else {
            document.getElementById('loaderbtnclose').click();
            document.getElementById('thankspagebtn').click();
            document.getElementById('apiresponsemsg').innerHTML = 'There was an error submitting the form, please try again';
            if (form.name == '' || form.name == null) {
              document.getElementById('apiresponsemsg').innerHTML += ' ( Name )';
            }
            if (form.name.indexOf(' ') == -1 && form.name != '') {
              document.getElementById('apiresponsemsg').innerHTML += ' ( Full Name )';
            }
          }
        }
      }
      Http.onreadystatechange = handleStateChange;

      const formData = new window.FormData(form);
      Http.open('POST', url);
      Http.send(formData);
    }
  };

  render() {
    return (
      <form id="schedule_form" onSubmit={this.submitfunc}>
        <div className="row">
          <div className="form-group col-md-6 col-sm-12">
            <input className={this.state.name.valid  ? "form-control fa-input " : "form-control fa-input  alert-danger"}
                   id="name"
                   placeholder={this.state.name.valid ? "Name*" : "Please Enter Full Name"}
                   type="text"
                   name="name"
                   value={this.state.name.value || ''}
                   onChange={this.changeInput}
                   required/>
          </div>
          {/* form-group */}
          <div className="form-group col-md-6 col-sm-12">
            <input aria-describedby="emailHelp"
                   className={`form-control fa-input ${this.state.email.valid ? "" : " alert-danger"}`}
                   id="email"
                   name="email"
                   value={this.state.email.value || ''}
                   onChange={this.changeInput}
                   placeholder={this.state.email.valid ? "Email*" : "Please enter either Email or Phone" }
                   type="email"
                   required/>
          </div>
          {/* form-group */}
        </div>
        <div className="row">
          <div className="form-group col-md-6 col-sm-12">
            <input className={`form-control fa-input ${this.state.phone.valid ? "" : " alert-danger"}`}
                   id="phone"
                   name="phone"
                   placeholder={this.state.phone.valid ? "Phone (Free PT consultation)" : "Please enter either Email or Phone" }
                   type="text"
                   maxLength={12}
                   value={this.state.phone.value || ''}
                   onChange={(e) => this.changePhone(e.target.value)}/>
          </div>
          {/* form-group */}
          <div className="form-group col-md-6 col-sm-12">
            <input
                   className={this.state.zip.valid  ? "form-control fa-input " : "form-control fa-input  alert-danger"}
                   id="zip"
                   placeholder={this.state.zip.valid ? "Zipcode" : "Please Valid Zip of 5 character" }
                   type="text"
                   name="zip"
                   value={this.state.zip.value || ''}
                   onChange={this.changeInput}
                   maxLength={5}
                   required/>
          </div>
          {/* form-group */}
        </div>
        <div className="row">
          <div className="form-group col-12">
                          <textarea className="form-control fa-input"
                                    id="reasonforvisit"
                                    rows={4}
                                    name="note"
                                    value={this.state.note.value || ''}
                                    onChange={this.changeInput}
                                    placeholder="Comment" />
          </div>
          {/* form-group */}
        </div>
        <button className="btn ca-btn btn-gr p-3 w-100 desk-form-sub apibutton" type="submit">Schedule a visit
        </button>
        <button className="btn ca-btn btn-gr p-3 w-100 mob-form-sub apibutton" type="submit">>Request a therapist
        </button>
      </form>
    )
  }
}


export default ScheduleForm
