import React from 'react'
import { Link } from 'gatsby'
import github from '../img/github-icon.svg'
import logo from '../img/logo.svg'

const Navbar = class extends React.Component {
  render() {
    return (
      <div className="phheader">
        <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-5 bg-white main-menu">
          <nav className="navbar navbar-expand-lg navbar navbar-dark w-100">
            <Link to="/" className="navbar-brand my-0 mr-md-auto"> <img src="/assets/img/logo.png" alt="" className="logo" /> </Link>
            <a className="fw-6 p-2 text-dark mob-menu-tel" href="tel:+18888590145" style={{fontWeight: 'bolder', fontSize: '16px'}}><i className="fa fa-phone" /> 888-859-0145</a>
            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse3">
              <span><i className="fa fa-bars" /></span>
            </button>
            <div className="collapse navbar-collapse " id="navbarCollapse3">
              <div className="navbar-nav">
                <Link className="p-2 text-dark" to="/why-pete">Why Pete?</Link>
                <Link className="p-2 text-dark" to="/#pricing_and_ins">Pricing &amp; Insurance</Link>
                <Link className="p-2 text-dark" to="/treatment-care">Treatment and Care</Link>
                <Link className="p-2 text-dark" to="/therapists">For Therapists</Link>
                <Link className="p-2 text-dark" to="/physician">For Physicians</Link>
                <Link className="p-2 text-dark covid-link" to="/covid">Covid-19</Link>
                <a className="p-2 text-dark mob-menu-tel2" href="tel:+18888590145" style={{color: '#00234e !important'}}>
                  <i className="fa fa-phone" /> 888-859-0145 </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}

export default Navbar
