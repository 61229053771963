import React from 'react'
import { Link } from 'gatsby'
import ScheduleForm from './ScheduleForm.js'


const Footer = class extends React.Component {

  render() {

    const renderFirstMenu = () => {
      if (this.props.menu) {
        return <><div className="row">
          <div className="col">
            <h5 className="mb-3"> Service<p />
            </h5>
            <ul className="list-group">
              <li className="ca-list"><Link to="/treatment-care">Conditions we treat</Link></li>
              <li className="ca-list"><Link to="/faq">FAQs</Link></li>
            </ul>
          </div>
          <div className="col">
            <h5 className="mb-3"> Company </h5>
            <ul className="list-group">
              <li className="ca-list"><Link to="/press">Press</Link></li>
              <li className="ca-list"><Link to="/blog">Blog</Link></li>

              <li className="ca-list"><Link to="/contact">Contact us</Link></li>
            </ul>
          </div>
          <div className="col">
            <h5 className="mb-3">Partners</h5>
            <ul className="list-group">
              <li className="ca-list"><Link to="/therapists">For therapist</Link></li>
              <li className="ca-list"><Link to="/physician">For physicians</Link></li>
            </ul>
          </div>
          <div className="col">
            <h5 className="mb-3">Legal&nbsp;&amp;&nbsp;Practices</h5>
            <ul className="list-group">
              <li className="ca-list"><Link to="/code_of_care">Code of Care</Link></li>
              <li className="ca-list"><Link to="/terms">Terms of use</Link></li>
              <li className="ca-list"><Link to="/privacy">Privacy policy</Link></li>
            </ul>
          </div>
          <div className="col-4">
            <h5 className="mb-3">Insurance</h5>
            <div className="row">
              <div className="col">
                <ul className="list-group">
                  <li className="ca-list"><Link to="/insurance?name=aetna"> Aetna</Link></li>
                  <li className="ca-list"><Link to="/insurance?name=anthem"> Anthem Blue Cross</Link></li>
                  <li className="ca-list"><Link to="/insurance?name=blueshield"> Blue Shield</Link></li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="ca-list"><Link to="/insurance?name=healthcare"> HealthCare Partners</Link></li>
                  <li className="ca-list"><Link to="/insurance?name=medicare"> Medicare CA South</Link></li>
                  <li className="ca-list"><Link to="/insurance?name=unitedhealthcare"> United Healthcare</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
          <div className="pt-3 pb-3 pl-5 pr-5">
            <hr className="white-hr"/>
          </div></>
      } else {
        return null
      }
    }

    const renderSecondMenu = () => {
      if (this.props.menu) {
        return <><div className="accordion" id="regionAccordion">
          <div className="card">
            <div className="card-header" id="headingOne"
                 style={{background: 'none !important', paddingLeft: '0px'}}>
              <h2 className="mb-0">
                <button className="btn pl-0" type="button" data-toggle="collapse" data-target="#collapseOne"
                        aria-expanded="true" aria-controls="collapseOne"
                        style={{background: 'none !important', border: 'none'}}>
                  <h5 className="mb-3 text-white text-light">
                    <img src="/assets/newimg/hamburger.png" style={{width: '14px', marginRight: '19px'}}/>
                    Locations in Los Angeles County
                  </h5>
                </button>
              </h2>
            </div>
            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                 data-parent="#regionAccordion" style={{background: 'none !important'}}>
              <div className="card-body" style={{background: 'none !important'}}>
                <div className="row mb-5">
                  <div className="col">
                    <ul className="list-group">
                      <li className="ca-list">Alhambra</li>
                      <li className="ca-list">Beverly Hills</li>
                      <li className="ca-list">Burbank</li>
                      <li className="ca-list">Century City</li>
                      <li className="ca-list">Glendale</li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul className="list-group">
                      <li className="ca-list">Hollywood</li>
                      <li className="ca-list">Long Beach</li>
                      <li className="ca-list">Los Angeles</li>
                      <li className="ca-list">Manhattan Beach</li>
                      <li className="ca-list">Marina del Rey</li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul className="list-group">
                      <li className="ca-list">Monterey Park</li>
                      <li className="ca-list">Northridge</li>
                      <li className="ca-list">Pasadena</li>
                      <li className="ca-list">Pomona</li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul className="list-group">
                      <li className="ca-list">Rancho Park</li>
                      <li className="ca-list">Redondo Beach</li>
                      <li className="ca-list">Santa Clarita</li>
                      <li className="ca-list">Santa Monica</li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul className="list-group">
                      <li className="ca-list">Sherman Oaks</li>
                      <li className="ca-list">Torrance</li>
                      <li className="ca-list">Van Nuys</li>
                      <li className="ca-list">Venice</li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul className="list-group">
                      <li className="ca-list">West Hollywood</li>
                      <li className="ca-list">Westlake</li>
                      <li className="ca-list">Westwood</li>
                      <li className="ca-list">Woodland Hills</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card" style={{background: 'none !important', border: 'none'}}>
            <div className="card-header" id="headingTwo" style={{background: 'none !important', paddingLeft: '0px'}}>
              <h2 className="mb-0">
                <button className="btn btn-link collapsed pl-0" type="button" data-toggle="collapse"
                        data-target="#collapseTwo" aria-expanded="false"
                        style={{background: 'none !important', border: 'none'}} aria-controls="collapseTwo">
                  <h5 className="mb-3  text-white text-light">
                    <img src="/assets/newimg/hamburger.png" style={{width: '14px', marginRight: '19px'}}/>
                    Locations in Orange County
                  </h5>
                </button>
              </h2>
            </div>
            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#regionAccordion">
              <div className="card-body" style={{background: 'none !important'}}>
                <div className="row mb-5">
                  <div className="col">
                    <ul className="list-group">
                      <li className="ca-list"><a href="#">Anaheim</a></li>
                      <li className="ca-list"><a href="#">Buena Park</a></li>
                      <li className="ca-list"><a href="#">Costa Mesa</a></li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul className="list-group">
                      <li className="ca-list"><a href="#">Fullerton</a></li>
                      <li className="ca-list"><a href="#">Garden Grove</a></li>
                      <li className="ca-list"><a href="#">Huntington Beach</a></li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul className="list-group">
                      <li className="ca-list"><a href="#">Irvine</a></li>
                      <li className="ca-list"><a href="#">Lake Forest</a></li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul className="list-group">
                      <li className="ca-list"><a href="#">Mission Viejo</a></li>
                      <li className="ca-list"><a href="#">Newport Beach</a></li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul className="list-group">
                      <li className="ca-list"><a href="#">Orange</a></li>
                      <li className="ca-list"><a href="#">Santa Ana</a></li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul className="list-group">
                      <li className="ca-list"><a href="#">Tustin</a></li>
                      <li className="ca-list"><a href="#">Westminster</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <br/>
          <br/></>
      } else {
        return null
      }
    }

    return (
      <>
      <footer className={`footer pt-5 ${this.props.menu ? "pb-1" : "pb-0 sony-page"}`}>
        <div className="container">

          {renderFirstMenu()}

          <div className="container">

            {renderSecondMenu()}

            <div className={`row  footer-bottom ${this.props.menu ? "pb-3" : "pb-5"}`}>
              <div className="col-md-4">
                <a href="https://twitter.com/wearepetehealth" className="mr-3" target="_blank">
                  <img src="/assets/img/twitter.png" alt=""/>
                </a>
                <a href="https://www.instagram.com/pete.health/" className="mr-3" target="_blank">
                  <img src="/assets/img/insta.png" alt=""/>
                </a>
                <a href="https://www.facebook.com/Pete-Health-111603563724953/?modal=admin_todo_tour" className="mr-3"
                   target="_blank">
                  <img src="/assets/img/facebook.png" alt=""/>
                </a>
              </div>
              <div className="col-md-4 d-flex justify-content-center align-items-center">
                <div className="f-7 text-c4c4c4">© {new Date().getFullYear()} PeteHealth. All Rights Reserved.</div>
              </div>
              <div className="col-md-4 text-right">
                <a href="tel:+18888590145" className="f-7"><img src="/assets/img/phone.png" alt=""/> 888-859-0145</a>
              </div>
            </div>
          </div>
        </div>

      </footer>
        {/* End FAQ Section */}
        {/* Popup Form */}
        {/* Button trigger modal */}
        {/* Modal */}
        <div className="modal fade" id="FormModalCenter" tabIndex={-1} role="dialog" aria-labelledby="FormModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button id="formmodalclose" type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <div className="therapist-form p-4 bg-white w-75 m-auto">
                  <div>
                    <ScheduleForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="modal fade" id="exampleModalted" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog trainerpopover" role="document">
              <div className="modal-content">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 p-0 m-0 md-300" style={{background: 'url("/assets/newimg/trainer1.png")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    </div>
                    <div className="col-md-6 col-sm-12 p-0 m-0">
                      <div className="content p-0 m-0 py-3">
                        <h3 className="pt-3 px-4" style={{fontSize: '1.4rem'}}>Ted Steben, MPT</h3><br />
                        <p className="pb-4 px-4">For over 16 years, Ted Steben has dedicated his life to being
                          the best outpatient physical therapist he can be. Since joining PeteHealth, Ted has
                          worked to incorporate manual therapy along with specialized therapeutic exercises
                          in order to effectively treat a wide range of post-operative cases and orthopedic
                          injuries for all body types.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModalalex" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog trainerpopover" role="document">
              <div className="modal-content">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 p-0 m-0 md-300" style={{background: 'url("/assets/newimg/trainer2.png")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    </div>
                    <div className="col-md-6 col-sm-12 p-0 m-0">
                      <div className="content p-0 m-0 py-3">
                        <h3 className="pt-3 px-4" style={{fontSize: '1.4rem'}}>Alex Lui, DPT</h3><br />
                        <p className="pb-4 px-4">For the past seven years, Alex Lui has helped patients with a
                          range of issues as an outpatient orthopedic physical therapist. At PeteHealth, he
                          specializes in manual therapy for the treatment of chronic pain. Alex believes in
                          the power of the body to heal itself and how physical therapy plays an integral
                          role in doing so.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModalamy" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog trainerpopover" role="document">
              <div className="modal-content">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 p-0 m-0 md-300" style={{background: 'url("/assets/newimg/trainer4.png")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    </div>
                    <div className="col-md-6 col-sm-12 p-0 m-0">
                      <div className="content p-0 m-0 py-3">
                        <h3 className="pt-3 px-4" style={{fontSize: '1.4rem'}}>Amy Smith, DPT</h3><br />
                        <p className="pb-4 px-4">A passionate therapist with over ten years of experience in
                          treating outpatient orthopedic injuries across Southern California, Amy Smith
                          specializes in manual therapy, spine rehabilitation, shoulder injuries and post-op
                          rehabilitation. Amy's experience and warm approach to patient care has helped
                          her become one of PeteHealth's most popular therapists.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModalcamille" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog trainerpopover" role="document">
              <div className="modal-content">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 p-0 m-0 md-300" style={{background: 'url("/assets/newimg/trainer3.png")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    </div>
                    <div className="col-md-6 col-sm-12 p-0 m-0">
                      <div className="content p-0 m-0 py-3">
                        <h3 className="pt-3 px-4" style={{fontSize: '1.4rem'}}>Camille Choi, DPT</h3><br />
                        <p className="pb-4 px-4">For PeteHealth, physical therapist Camille Choi blends
                          myofascial release and neuromuscular re-education to reprogram dysfunctional
                          movement patterns. Camille easily identifies the habitual problems which arise from
                          our Western society such as sitting, computer work,driving, and cell phone use, and
                          works to offset these negative effects through her treatments.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModalcindy" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog trainerpopover" role="document">
              <div className="modal-content">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 p-0 m-0 md-300" style={{background: 'url("/assets/newimg/trainer7.png")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    </div>
                    <div className="col-md-6 col-sm-12 p-0 m-0">
                      <div className="content p-0 m-0 py-3">
                        <h3 className="pt-3 px-4" style={{fontSize: '1.4rem'}}>Cindy Glaser, DPT</h3><br />
                        <p className="pb-4 px-4">Since graduating from the University of Southern California
                          four years ago, Cindy Glaser has been serving patients in a wide variety of
                          physical therapeutic capacities. An orthopedic clinical specialist and certified
                          Pilates instructor, Cindy serves Pete Health as a specialized physical therapist in
                          the field of spinal rehabilitation, chronic pain and movement retraining.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModaldave" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog trainerpopover" role="document">
              <div className="modal-content">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 p-0 m-0 md-300" style={{background: 'url("/assets/newimg/trainer5.png")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    </div>
                    <div className="col-md-6 col-sm-12 p-0 m-0">
                      <div className="content p-0 m-0 py-3">
                        <h3 className="pt-3 px-4" style={{fontSize: '1.4rem'}}>Dave Halberg, PT</h3><br />
                        <p className="pb-4 px-4">A 34-year veteran in the physical therapy space, Dave Halberg
                          has relied on his strong grasp of mechanical physics, as well as his passion for
                          troubleshooting, to find impactful solutions for his patients. Dave has a deep
                          understanding of how the human body works and finding the cause of the problem to
                          help produce rewarding results for PeteHealth patients</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><button id="thankspagebtn" type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#thanks">Launch demo modal</button>
          <div className="modal fade" id="thanks" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered text-center" role="document">
              <div className="modal-content text-center">
                <div className="modal-body text-center">
                  <h3 id="apiresponsemsg">Thank you for your request, someone will contact you soon</h3>
                </div>
                <div className="modal-footer" style={{textAlign: 'center', justifyContent: 'center', padding: '5px'}}>
                  <button type="button" className="btn btn-secondary btn-gr" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div><button id="loaderbtn" type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#loader">Launch demo modal</button>
          <div className="modal fade" id="loader" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered text-center" role="document">
              <button id="loaderbtnclose" type="button" className="close" style={{display: 'none'}} data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
              <div className="modal-content text-center" style={{background: 'none', border: 'none', boxShadow: 'none'}}>
                <div className="modal-body text-center">
                  <div className="spinner-grow text-center" role="status">
                    <span className="sr-only text-center">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

Footer.defaultProps = {
  menu: true
};

export default Footer
